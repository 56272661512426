import { Dialog } from '@headlessui/react'
import { INFTCreateRoundDialog } from '../../../types/dialogs'
import DialogWrapper from '../../dialogs/DialogWrapper'
import { useForm, SubmitHandler } from "react-hook-form";
import { useEffect, useState } from "react";
import { FaArrowAltCircleUp, FaCheckCircle, FaTimes, FaSpinner } from "react-icons/fa"
import ERC721Staking from "../../../abi/ERC721Staking.json"
import { useContract, useContractWrite } from '@thirdweb-dev/react';
import { TransactionError } from '@thirdweb-dev/sdk';

interface IWrapper {
    isOpen: boolean
    openModal: () => void
    closeModal: () => void
    children: JSX.Element | JSX.Element[]
}

type Inputs = {
    contractAddress: string,
    startTime: number,
    duration: number,
};

function Wrapper({ isOpen, openModal, closeModal, children }: IWrapper) {
    return (
        <DialogWrapper isOpen={isOpen} openModal={openModal} closeModal={closeModal}>
            <Dialog.Title
                as="h3"
                className="text-lg font-semibold leading-6 text-slate-900 uppercase"
            >
                <div className='flex items-center justify-between'>
                    <span>Create Round</span>
                    <button onClick={closeModal}><FaTimes /></button>
                </div>
            </Dialog.Title>
            <div className="mt-8">{children}</div>
        </DialogWrapper>
    )
}


export default function NFTCreateRoundDialog({ contractAddress, isOpen, openModal, closeModal }: INFTCreateRoundDialog) {

    const { contract: NFTStakingContracts } = useContract(contractAddress, ERC721Staking.abi)

    const { mutateAsync: createRound, status: stateCreateRound, error: error } = useContractWrite(NFTStakingContracts, "createRound")

    const { register, handleSubmit, setFocus, formState: { errors } } = useForm<Inputs>();

    const [startTime, setStartTime] = useState<number>()
    const [duration, setDuration] = useState<number>()

    const onSubmit: SubmitHandler<Inputs> = async (data) => {

        const duration = data.duration
        const startTime = data.startTime
        setStartTime(startTime)
        setDuration(duration)

        await createRound([startTime, duration])
    };

    useEffect(() => {
        setFocus("startTime")
    }, [setFocus])

    if (stateCreateRound === "loading") {
        return (<Wrapper isOpen={isOpen} openModal={openModal} closeModal={closeModal}>
                <div className="flex flex-col gap-4 items-center text-center mb-8">
                    <h4 className="text-sm text-slate-400 uppercase">Step 1 of 1</h4>
                    <FaSpinner className="animate-spin h-6 w-6" />
                    <h3 className="font-semibold uppercase text-slate-900">Create Round</h3>
                    <h4 className="font-semibold uppercase text-slate-900">Start Time: {startTime}</h4>
                    <h4 className="font-semibold uppercase text-slate-900">Duration: {duration}</h4>
                    <p className="text-slate-400">Confirm this transaction in your wallet</p>
                </div>
            </Wrapper>
        )
    }

    if (stateCreateRound === "error") {
        return (<Wrapper isOpen={isOpen} openModal={openModal} closeModal={closeModal}>
                <div className="flex flex-col gap-4 items-center text-center mb-8">
                    <h4 className="text-sm text-slate-400 uppercase">Error</h4>
                    <h3 className="font-semibold uppercase text-red-900">Oops! Something went wrong.</h3>
                    <p className="text-slate-400 w-full text-xs font-mono">
                        {(error as TransactionError).toString()}
                    </p>
                </div>
            </Wrapper>
        )
    }

    if (stateCreateRound === "success") {
        return (<Wrapper isOpen={isOpen} openModal={openModal} closeModal={closeModal}>
                <div className="flex flex-col gap-4 items-center text-center mb-8">
                    <h4 className="text-sm text-slate-400 uppercase">Congratulations</h4>
                    <FaCheckCircle className="text-emerald-500 h-6 w-6" />
                    <h3 className="font-semibold uppercase text-emerld-900">Created Round</h3>
                    <h4 className="font-semibold uppercase text-slate-900">Start Time: {startTime}</h4>
                    <h4 className="font-semibold uppercase text-slate-900">Duration: {duration}</h4>
                    <p className="text-slate-400">Round successfully created. View the round created in the Rounds session.</p>
                </div>
            </Wrapper>
        )
    }

    return (
        <Wrapper isOpen={isOpen} openModal={openModal} closeModal={closeModal}>
            <>
            <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4">

                <div className="flex justify-between items-center text-xs font-semibold text-slate-400 uppercase">
                    <label>Start Time</label>
                </div>

                <div className="flex">
                    <input autoFocus={true} {...register("startTime", { required: true })} className="grow outline-0 border-2 border-r-2 border-emerald-500 text-3xl py-1 px-2 rounded-md text-slate-600" />
                </div>

                {errors.startTime && <span>This field is required</span>}

                <div className="flex justify-between items-center text-xs font-semibold text-slate-400 uppercase">
                    <label>Duration</label>
                </div>

                <div className="flex">
                    <input autoFocus={true} {...register("duration", { required: true })} className="grow outline-0 border-2 border-r-2 border-emerald-500 text-3xl py-1 px-2 rounded-md text-slate-600" />
                </div>

                {errors.duration && <span>This field is required</span>}

                <button type="submit" className="text-xl font-semibold text-white py-3 px-4 bg-emerald-500 rounded-md hover:border-emerald-500 hover:bg-emerald-400">
                    <div className="flex items-center justify-center gap-2">
                        <FaArrowAltCircleUp className="h-4" />
                        <span>Create Round</span>
                    </div>
                </button>

            </form>
            </>
        </Wrapper>
    )
}