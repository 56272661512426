import { ethers, BigNumber } from "ethers";
import { useForm, SubmitHandler } from "react-hook-form";
import { FaArrowAltCircleUp, FaCheckCircle, FaTimes, FaHandPointDown } from "react-icons/fa"
import { tokenStakingAddress } from "../../../constants";
import ERC20Staking from "../../../abi/ERC20Staking.json"
import { FaSpinner } from "react-icons/fa"
import { useEffect, useState } from "react";
import StateDialog from "../../dialogs/StateDialog";
import { useContract, useContractWrite } from "@thirdweb-dev/react";
import { TransactionError } from "@thirdweb-dev/sdk";

type Inputs = {
    role: string,
    wallet: string,
};

export default function GrantRoleForm() {

    const { contract: stakingContract } = useContract(tokenStakingAddress, ERC20Staking.abi)
    const { mutateAsync: grantRole, status: stateGrantRole, error: error } = useContractWrite(stakingContract, "grantRole");

    const { register, handleSubmit, setFocus, formState: { errors } } = useForm<Inputs>();

    const [role, setRole] = useState<string>()
    const [wallet, setWallet] = useState<string>()

    const onSubmit: SubmitHandler<Inputs> = async (data) => {

        const role = data.role
        const wallet = data.wallet
        setRole(role)
        setWallet(wallet)

        await grantRole([role, wallet])
    };

    useEffect(() => {
        setFocus("role")
    }, [setFocus])

    if (stateGrantRole === "loading") {
        return (
            <div className="flex flex-col gap-4 items-center text-center mb-8">
                <h4 className="text-sm text-slate-400 uppercase">Step 1 of 1</h4>
                <FaSpinner className="animate-spin h-6 w-6" />
                <h3 className="font-semibold uppercase text-slate-900">Grant Role</h3>
                <h4 className="font-semibold uppercase text-slate-900">Role: {role}</h4>
                <h4 className="font-semibold uppercase text-slate-900">Address: {wallet}</h4>
                <p className="text-slate-400">Confirm this transaction in your wallet</p>
            </div>
        )
    }

    if (stateGrantRole === "error") {
        return (
            <div className="flex flex-col gap-4 items-center text-center mb-8">
                <h4 className="text-sm text-slate-400 uppercase">Error</h4>
                <h3 className="font-semibold uppercase text-red-900">Oops! Something went wrong.</h3>
                <p className="text-slate-400 w-full text-xs font-mono">
                    {(error as TransactionError).toString()}
                </p>
            </div>
        )
    }

    if (stateGrantRole === "success") {
        return (
            <div className="flex flex-col gap-4 items-center text-center mb-8">
                <h4 className="text-sm text-slate-400 uppercase">Congratulations</h4>
                <FaCheckCircle className="text-emerald-500 h-6 w-6" />
                <h3 className="font-semibold uppercase text-emerld-900">Grant Role Succeeded</h3>
                <h4 className="font-semibold uppercase text-slate-900">Role: {role}</h4>
                <h4 className="font-semibold uppercase text-slate-900">Address: {wallet}</h4>
                <p className="text-slate-400">Roles successfully granted. View the transaction in the your wallet.</p>
            </div>
        )
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4">

            <div className="flex justify-between items-center text-xs font-semibold text-slate-400 uppercase">
                <label>Role</label>
            </div>

            <div className="flex">
                <input autoFocus={true} {...register("role", { required: true })} className="grow outline-0 border-2 border-r-2 border-emerald-500 text-3xl py-1 px-2 rounded-md text-slate-600" />
            </div>
            
            {errors.role && <span>This field is required</span>}

            <div className="flex justify-between items-center text-xs font-semibold text-slate-400 uppercase">
                <label>Address</label>
            </div>

            <div className="flex">
                <input autoFocus={true} {...register("wallet", { required: true })} className="grow outline-0 border-2 border-r-2 border-emerald-500 text-3xl py-1 px-2 rounded-md text-slate-600" />
            </div>

            {errors.wallet && <span>This field is required</span>}

            <button type="submit" className="text-xl font-semibold text-white py-3 px-4 bg-emerald-500 rounded-md hover:border-emerald-500 hover:bg-emerald-400">
                <div className="flex items-center justify-center gap-2">
                    <FaArrowAltCircleUp className="h-4" />
                    <span>Grant Role</span>
                </div>
            </button>

        </form>
    );
}