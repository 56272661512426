import { ethers, BigNumber } from "ethers";
import { useForm, SubmitHandler } from "react-hook-form";
import { FaArrowAltCircleUp, FaCheckCircle } from "react-icons/fa"
import { tokenStakingAddress } from "../../../constants";
import ERC20Staking from "../../../abi/ERC20Staking.json"
import { FaSpinner } from "react-icons/fa"
import { useEffect, useState } from "react";
import { useContract, useContractWrite } from "@thirdweb-dev/react";
import { TransactionError } from "@thirdweb-dev/sdk";
import { formatCommify } from "../../../support/formatters";

type Inputs = {
    roundIndex: number,
    amount: string,
};

export default function DepositFundsForm() {

    const { contract: stakingContract } = useContract(tokenStakingAddress, ERC20Staking.abi)

    const { mutateAsync: depositFunds, status: stateDepositFunds, error: error } = useContractWrite(stakingContract, "depositFunds")

    const { register, handleSubmit, setFocus, formState: { errors } } = useForm<Inputs>();

    const [roundIndex, setRoundIndex] = useState<number>()
    const [amount, setAmount] = useState<BigNumber>()

    const onSubmit: SubmitHandler<Inputs> = async (data) => {

        const roundIndex = data.roundIndex
        const amount = ethers.utils.parseEther(data.amount)
        if (amount.lte(0)) return        
        setRoundIndex(roundIndex)
        setAmount(amount)

        await depositFunds([roundIndex, amount, { value: amount }])
    };

    useEffect(() => {
        setFocus("roundIndex")
    }, [setFocus])

    if (stateDepositFunds === "loading") {
        return (
            <div className="flex flex-col gap-4 items-center text-center mb-8">
                <h4 className="text-sm text-slate-400 uppercase">Step 1 of 1</h4>
                <FaSpinner className="animate-spin h-6 w-6" />
                <h3 className="font-semibold uppercase text-slate-900">Deposit Funds</h3>
                <h4 className="font-semibold uppercase text-slate-900">Round Index: {roundIndex}</h4>
                <h4 className="font-semibold uppercase text-slate-900">Amount: {formatCommify(amount)} BNB</h4>
                <p className="text-slate-400">Confirm this transaction in your wallet</p>
            </div>
        )
    }

    if (stateDepositFunds === "error") {
        return (
            <div className="flex flex-col gap-4 items-center text-center mb-8">
                <h4 className="text-sm text-slate-400 uppercase">Error</h4>
                <h3 className="font-semibold uppercase text-red-900">Oops! Something went wrong.</h3>
                <p className="text-slate-400 w-full text-xs font-mono">
                    {(error as TransactionError).toString()}
                </p>
            </div>
        )
    }

    if (stateDepositFunds === "success") {
        return (
            <div className="flex flex-col gap-4 items-center text-center mb-8">
                <h4 className="text-sm text-slate-400 uppercase">Congratulations</h4>
                <FaCheckCircle className="text-emerald-500 h-6 w-6" />
                <h3 className="font-semibold uppercase text-emerld-900">Funds Deposited</h3>
                <h4 className="font-semibold uppercase text-slate-900">Round Index: {roundIndex}</h4>
                <h4 className="font-semibold uppercase text-slate-900">Amount: {formatCommify(amount)} BNB</h4>
                <p className="text-slate-400">View the funds deposited in the Rounds session.</p>
            </div>
        )
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4">

            <div className="flex justify-between items-center text-xs font-semibold text-slate-400 uppercase">
                <label>Round Index</label>
            </div>

            <div className="flex">
                <input autoFocus={true} {...register("roundIndex", { required: true })} className="grow outline-0 border-2 border-r-2 border-emerald-500 text-3xl py-1 px-2 rounded-md text-slate-600" />
            </div>
            
            {errors.roundIndex && <span>This field is required</span>}

            <div className="flex justify-between items-center text-xs font-semibold text-slate-400 uppercase">
                <label>Amount</label>
            </div>

            <div className="flex">
                <input autoFocus={true} {...register("amount", { required: true })} className="grow outline-0 border-2 border-r-2 border-emerald-500 text-3xl py-1 px-2 rounded-md text-slate-600" />
            </div>

            {errors.amount && <span>This field is required</span>}

            <button type="submit" className="text-xl font-semibold text-white py-3 px-4 bg-emerald-500 rounded-md hover:border-emerald-500 hover:bg-emerald-400">
                <div className="flex items-center justify-center gap-2">
                    <FaArrowAltCircleUp className="h-4" />
                    <span>Deposit Funds</span>
                </div>
            </button>

        </form>
    );
}