import { useAddress, useContract, useContractRead } from "@thirdweb-dev/react"
import Loading from "../../support/Loading"
import NFTWithdrawButton from "../buttons/NFTWithdrawButton"
import tokenOfOwnerByIndexes from "../../../helpers/tokenOfOwnerByIndexes"
import StakedNFT from "./StakedNFT"
import ERC721Staking from "../../../abi/ERC721Staking.json"
import DropERC721 from "../../../abi/DropERC721.json"
import { BigNumber } from "ethers"
import { Key, useEffect, useState } from "react"

interface IStakedNFTList {
    contractAddress: string
}

export default function StakedNFTList({ contractAddress }: IStakedNFTList) {

    const address = useAddress()

    const { contract } = useContract(contractAddress, ERC721Staking.abi)
    const { data: nftDropAddress } = useContractRead(contract, "nftCollection")
    const { contract: token } = useContract(nftDropAddress, DropERC721)

    const { data: depositedTokenIdsForAddress, isLoading } = useContractRead(contract, "depositedTokenIdsForAddress", address)

    const [indexArray, setIndexArray] = useState<BigNumber[]>([])

    /* console.log(depositedTokenIdsForAddress.length) */

    /* useEffect(() => { 

        async function fetchTokenIds(array: number[]) {
            const tokenIds = await tokenOfOwnerByIndexes(nftDropAddress, array, address)
            setIndexArray(tokenIds)
        }

        if (depositedTokenIdsForAddress) {
            const array = Array.from(Array(depositedTokenIdsForAddress).keys())
            fetchTokenIds(array)
        }
    }, [depositedTokenIdsForAddress, address, contract]) */

    return (
        <div className="bg-slate-700 text-slate-50 p-4 rounded-lg">
            <div className="flex flex-col justify-between gap-4 h-full">
                <div className="flex justify-between items-center text-slate-50">
                    <div className="font-semibold flex gap-1 items-center text-white text-xs font-semibold uppercase">
                        {depositedTokenIdsForAddress ?
                            <div>{!isLoading && depositedTokenIdsForAddress?.length}</div>
                            :
                            <Loading />
                        }
                        <div>Staked</div>
                    </div>
                    {depositedTokenIdsForAddress && depositedTokenIdsForAddress.length > 0 && <NFTWithdrawButton contractAddress={contractAddress} tokenIds={depositedTokenIdsForAddress} text="Withdraw All" />}
                </div>
                <div>
                    {!depositedTokenIdsForAddress && <div className="text-center animate-pulse">Loading...</div>}
                    {nftDropAddress && depositedTokenIdsForAddress && depositedTokenIdsForAddress?.map((tokenId: BigNumber, key: Key | null | undefined) => <StakedNFT key={key} tokenId={tokenId} contractAddress={contractAddress} nftDropAddress={nftDropAddress} />)}
                </div>
                <div className="text-xs text-slate-400 font-mono">{contractAddress}</div>
            </div>
        </div>
    )

}