import { useAddress, useContract, useContractRead } from "@thirdweb-dev/react"
import NFTStakingTable from "./NFTStakingTable"
import OwnedNFTList from "./owned/OwnedNFTList"
import StakedNFTList from "./staked/StakedNFTList"
import ERC721Staking from "../../abi/ERC721Staking.json"
import AdmNFTTable from "./AdmNFTTable"

interface INFTWrapper {
    contractAddress: string
}

export default function NFTWrapper({ contractAddress }: INFTWrapper) {
    const address = useAddress()
    const { contract } = useContract(contractAddress, ERC721Staking.abi)
    const { data: hasRole, isLoading: isLoadingRound } = useContractRead(contract, "hasRole", "0x0000000000000000000000000000000000000000000000000000000000000000", address);
    
    return (
        <div className="flex flex-col gap-4">
            {!hasRole? <></> :
            <div>
                <AdmNFTTable contractAddress={contractAddress} />
            </div>
            }
            <div className="grid lg:grid-cols-2 gap-4">
                <OwnedNFTList contractAddress={contractAddress} />
                <StakedNFTList contractAddress={contractAddress} />
            </div>
            <div>
                <NFTStakingTable contractAddress={contractAddress} />
            </div>
        </div>
    )
}