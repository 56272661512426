import { Dialog } from '@headlessui/react'
import { INFTDepositDialog } from '../../../types/dialogs'
import DialogWrapper from '../../dialogs/DialogWrapper'
import { FaArrowAltCircleUp, FaCheckCircle, FaHandPointUp, FaSpinner, FaTimes } from "react-icons/fa"
import DropERC721 from "../../../abi/DropERC721.json"
import ERC721Staking from "../../../abi/ERC721Staking.json"
import StateDialog from '../../dialogs/StateDialog'
import { useAddress, useContract, useContractRead, useContractWrite } from '@thirdweb-dev/react'
import { TransactionError } from '@thirdweb-dev/sdk'
import { BigNumber } from 'ethers'

interface IWrapper {
    isOpen: boolean
    openModal: () => void
    closeModal: () => void
    children: JSX.Element | JSX.Element[]
}

type Inputs = {
    tokenId: BigNumber,
};

function Wrapper({ isOpen, openModal, closeModal, children }: IWrapper) {
    return (
        <DialogWrapper isOpen={isOpen} openModal={openModal} closeModal={closeModal}>
            <Dialog.Title
                as="h3"
                className="text-lg font-semibold leading-6 text-slate-900 uppercase"
            >
                <div className='flex items-center justify-between'>
                    <span>Deposit</span>
                    <button onClick={closeModal}><FaTimes /></button>
                </div>
            </Dialog.Title>
            <div className="mt-8">{children}</div>
        </DialogWrapper>
    )
}

export default function NFTDepositDialog({ tokenIds, contractAddress, isOpen, openModal, closeModal }: INFTDepositDialog) {

    const address = useAddress()
    const { contract } = useContract(contractAddress, ERC721Staking.abi)

    const { data: nftDropAddress } = useContractRead(contract, "nftCollection")

    const { contract: token } = useContract(nftDropAddress, DropERC721)

    //const isApprovedForAll = useIsApprovedForAll(nftDropAddress, address, contractAddress) isApprovedForAll

    const { data: ApproveForAll, status: stateApprovalForAll, error: approvalForAllError } = useContractRead(token, "isApprovedForAll", address, contractAddress)
    //const { data: ApproveForThis, status: stateApprovalForThis, error: approvalForThisError } = useContractRead(token, "getApproved", tokenIds)
    const { mutateAsync: approve, status: stateApproval, error: approvalError } = useContractWrite(token, "approve")
    const { mutateAsync: depositNFT, status: stateDeposit, error: depositError } = useContractWrite(contract, "depositNFT")

    const tokenIdsString = tokenIds.map(bn => ` #${bn.toString()}`).toString()
    const tokenIdsNUmber = tokenIds.map(bn => `${bn.toString()}`).toString()

    /* console.log(ApproveForAll.toString()) */

    async function approveAll() {
        //console.log(contractAddress, tokenIdsNUmber)
        await approve([contractAddress, tokenIdsNUmber])
    }

    async function deposit() {
        //console.log(contractAddress, tokenIds.toString())
        await depositNFT([tokenIds])
    }

    if (stateApproval === "loading") {
        return (<Wrapper isOpen={isOpen} openModal={openModal} closeModal={closeModal}><StateDialog
            heading="Approve NFT Transfer"
            icon={<FaHandPointUp className="animate-pulse h-6 w-6" />}
            subheading={tokenIdsString}
            description="Confirm this transaction in your wallet"
        /></Wrapper>)
    }

    /* if (stateApproval === "idle") {
        return (
            <Wrapper isOpen={isOpen} openModal={openModal} closeModal={closeModal}><StateDialog
                heading="Mining Approval"
                icon={<FaSpinner className="animate-spin h-6 w-6" />}
                subheading={tokenIdsString}
                description="Your transaction is processing"
            /></Wrapper>)
    } */

    if (stateApproval === "error") {
        return (
            <Wrapper isOpen={isOpen} openModal={openModal} closeModal={closeModal}><StateDialog
                heading="Approval Failed"
                icon={<FaTimes className="h-6 w-6" />}
                subheading=""
                description={(approvalError as TransactionError).toString()}
            /></Wrapper>)
    }

    if (stateDeposit === "loading") {
        return (
            <Wrapper isOpen={isOpen} openModal={openModal} closeModal={closeModal}><StateDialog
                heading="Deposit NFTs"
                icon={<FaHandPointUp className="animate-pulse h-6 w-6" />}
                subheading={tokenIdsString}
                description="Confirm this transaction in your wallet"
            /></Wrapper>)
    }

/*     if (stateDeposit === "idle") {
        return (
            <Wrapper isOpen={isOpen} openModal={openModal} closeModal={closeModal}><StateDialog
                heading="Mining Deposit"
                icon={<FaSpinner className="animate-spin h-6 w-6" />}
                subheading={tokenIdsString}
                description="Your transaction is processing"
            /></Wrapper>)
    } */

    if (stateDeposit === "error") {
        return (
            <Wrapper isOpen={isOpen} openModal={openModal} closeModal={closeModal}><StateDialog
                heading="Deposit Failed"
                icon={<FaTimes className="h-6 w-6" />}
                subheading=""
                description={(depositError as TransactionError).toString()}
            /></Wrapper>)
    }

    if (stateDeposit === "success") {
        return (
            <Wrapper isOpen={isOpen} openModal={openModal} closeModal={closeModal}>
                <StateDialog
                    heading="Deposit Succeeded"
                    icon={<FaCheckCircle className="text-emerald-500 h-6 w-6" />}
                    subheading="NFTs successfully deposited"
                    description=""
                />
            </Wrapper>
        )
    }
    return (
        <Wrapper isOpen={isOpen} openModal={openModal} closeModal={closeModal}>
            <>
                <div className='text-center my-4'>
                    <h3 className="font-semibold uppercase text-emerld-900">Deposit NFT(s)</h3>
                    <h4 className="font-semibold uppercase text-slate-400">
                        {tokenIdsString}
                    </h4>
                </div>

                <div className="flex gap-4">
                    {!ApproveForAll &&
                        <button onClick={approveAll} className="grow text-xl font-semibold text-white py-3 px-4 bg-emerald-500 rounded-md hover:border-emerald-500 hover:bg-emerald-400">
                            <div className="flex items-center justify-center gap-2">
                                <span>Approve</span>
                            </div>
                        </button>
                   }
                    <button onClick={deposit} /* disabled={!isApprovedForAll} */ className="grow text-xl font-semibold text-white py-3 px-4 bg-emerald-500 rounded-md hover:border-emerald-500 hover:bg-emerald-400 disabled:bg-slate-400">
                        <div className="flex items-center justify-center gap-2">
                            <FaArrowAltCircleUp className="h-4" />
                            <span>Deposit</span>
                        </div>
                    </button>
                </div>
            </>
        </Wrapper>
    )
}
